import React from 'react'
import masterOrder from '../Image/masterOrder.png';
import shakehand from '../Image/shakehand.png';
function JoinUs() {
  return (
    <div>
       <section className="py-4 banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 my-auto">
                       <h6>成為維修王</h6>
                    </div>
                    <div className="col-md-8 my-auto">
                        <h6 className="float-end">
                        </h6>
                    </div>
                </div>
            </div>
      </section>
      <section className="section border-bottom">
          <div className="container">
              <h6 className="main-heading">宗旨</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                <div className="aboutUs-infor">
                    我們歡迎全港盡責任,以客戶為本的維修師傅加盟.希望各位維修師傅可透過我們的平台,提升香港家居維修水平,從而增加收入.
                </div>
              </div>
          </div>
       
      </section>
      <section className="section border-bottom">
      
          <div className="container">
              <h6 className="main-heading">如何加盟</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                <div className="aboutUs-infor">
                  <p>為了統一維修師傅水平,我們誠邀各師父進行技術測試,測試項目以日常能接觸到的家居維修項目.測試項目如下:</p>
                  <ul>
                    <li>玻璃膠運用技巧</li>
                    <li>.........</li>
                    <li>.........</li>
                  </ul>
                  <p>測試費用只需HK$XXXX</p>
                  <p>測試合格後,可獲盼發維修王認證,正式成為維修王.</p>
                  <p>維修王認證年費只需HK$XXX</p>
                  <p>各維修王可享用維修王平台接柯打</p>
                 </div>
                 <div className="aboutUs-Image">
                <img src={shakehand} className='img-fluid' alt="FindHelper"/>  
                </div>
              </div>
            </div>
            <div className="container">
              <h6 className="main-heading">如何接柯打?</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                <div className="aboutUs-infor">
                  <p>1.所有合格的維修王為收到登入密碼,透過填入註冊電話號碼及密碼登入,便可進入</p>
                  <p>2.進入待配對柯打頁面,若想接柯打,只需按-我想幫手-按鈕便可.你的資料將自動發放給客戶</p>
                  <p>3.耐心等候客戶聯絡,報價便可...</p>
                </div>
                <div className="aboutUs-Image">
                <img src={masterOrder} className='img-fluid' alt="FindHelper"/>  
                </div>
              </div>
          </div>
      </section>
    </div>


    
  )
}

export default JoinUs
