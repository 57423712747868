import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
    let data = [
        {
            id: 0,
            activeLink:false
        },
        {
            id: 1,
            activeLink:false
        },
        {
            id: 2,
            activeLink:false
        },
        {
            id: 3,
            activeLink:false
        },
        {
            id: 4,
            activeLink:false
        },
   

    ]
    //initial setting for active state-> aboutUs and contact navbar
    const [activeState, setActiveState] = useState([data]);
    //initial setting for homeState ->home navbar
    const [homeState, setHomeState] = useState(true)
    
    //change the link to active when click contact and above navbar
    const changeState = (id) => {
        setActiveState(
            data.map(dataItem => 
                dataItem.id === id ? {
                    ...dataItem, activeLink: true
               }:dataItem
            )
        )
        setHomeState(false)
    }
   

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow">
        <div className="container">
            <Link to="/" className="navbar-brand">
                <div className="logo-content">
                    <div className="logo-image">
                        <FontAwesomeIcon icon={faHammer} />      
                    </div>
                    <div className="logo-title">
                        <span id="chinese-title">維修王</span>
                        <span id="english-title">Home Repair Expert Hong Kong</span>    
                    </div>    
                </div>
                
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link to="/" className={homeState == true ? "nav-link active" : "nav-link"}
                            onClick={() =>setHomeState(true)}>主頁
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/course" className={data[0].activeLink == true ? "nav-link active" : "nav-link"}
                            onClick={() => changeState(0)}>維修王認證課程
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/aboutUs" className={data[1].activeLink == true ? "nav-link active" : "nav-link"}
                            onClick={() => changeState(0)}>服務特點
                        </Link>
                    </li>    
                    <li className="nav-item">
                        <Link to="/findHelper" className={data[2].activeLink == true ? "nav-link active" : "nav-link"}
                            onClick={() => changeState(1)}>如何找幫手?
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/joinUs" className={data[3].activeLink == true ? "nav-link active" : "nav-link"}
                            onClick={() => changeState(2)}>成為維修王 
                        </Link>
                    </li>
               
                        
                        
                        
                </ul>
            </div>
        </div>
    </nav>
        
    );
}

export default Navbar;