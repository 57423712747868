import React from 'react'
import cheatPhone from '../Image/cheatPhone.png';
import service from '../Image/service.png';
import serviceRating from '../Image/serviceRating.png';
import serviceRecord from '../Image/serviceRecord.png';

function Findhelper() {
  return (
    <div>
      <section className="py-4 banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 my-auto">
                       <h6>如何找幫手?</h6>
                    </div>
                    <div className="col-md-8 my-auto">
                        <h6 className="float-end">

                        </h6>
                    </div>
                </div>
            </div>
      </section>
      <section className="section border-bottom">
          <div className="container">
          <h6 className="main-heading">免滋擾式平台</h6>
              <div className='underline'></div>
              <div className="aboutUs-Image">
                <img src={cheatPhone} className='img-fluid' alt="FindHelper"/>  
                </div>
              <div className="aboutUs-contain">
                  <div className="aboutUs-infor">
                      <p>有見近年推銷電話數量大增,加上當中含有詐騙成份.個人電話號碼私隱性更為重要,尤其對長者朋友.現時市面的維修平台,
                          是把服務申請者的電話號碼,個人資料公開發放.一經申請服務,每每在盒時間收到十多個服價電話回覆.對服務申請者帶來極大困擾.
                      </p>
                      <p>
                        有見及此,我們自發的家居維修平台,是免滋擾式.只有客戶的居住區或,家居維修種類會被發佈至已通過我們註冊的維修師傅.
                        服務申請者的個人資料完全得到保障.因此用戶可安心享用.
                      </p>
                  </div>
              
              </div>
          </div>
          <div className="container">
              <h6 className="main-heading">費用全免</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                  <div className="aboutUs-infor">
                      <p>
                        用戶只需簡單註冊,便可永久成為會員,免費享用維修王平台.
                      </p>
                  </div>
                
              </div>
            </div>
            <div className="container">
              <h6 className="main-heading">簡單易用</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                  <div className="aboutUs-infor">
                      <p>
                        1.註冊後,用 Google 戶口登入..
                      </p>
                      <p>
                        2.選取維修服務後,發佈訊息...
                      </p>
                      <p>
                        3.耐心等候有興趣為閣下服務的師傅申請..
                      </p>
                      <p>
                        4.細閱各師傅的評價,維修經驗後,自行聯絡師傅索取報價...
                      </p>
                  </div>
                  <div className="aboutUs-Image">
                      <img src={service} className='img-fluid' alt="FindHelper"/>  
                   </div>
                </div>
          
            </div>
            <div className="container">
              <h6 className="main-heading">完成服務後,謹記按完成[保障客戶權益].</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                  <div className="aboutUs-infor">
                      <p>
                        我們會把你的維修記錄,存在個人戶口.方便日查詢及跟進.若無按完成,我們將無法幫你跟進閣下已完成的維修事項.
                        這是保障客戶的重要記錄.
                      </p>
                  </div>
                  <div className="aboutUs-Image">
                      <img src={serviceRecord} className='img-fluid' alt="FindHelper"/>  
                   </div>
              </div>
            </div>
            <div className="container">
              <h6 className="main-heading">對師傅進行不記名式評分.</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                  <div className="aboutUs-infor">
                      <p>
                        為推高師傅服務質數,我們誠邀用戶在完成家居服務後,對師傅表現進評分.整個評分以不記名式進行,所有記錄只供內部參考.
                        你對師傅的評分會存在你的個人戶口內,以供日後參考.你的評分有助我們提升配對效果.特此感謝你的貢獻.
                      </p>
                  </div>
                  <div className="aboutUs-Image">
                      <img src={serviceRating} className='img-fluid' alt="FindHelper"/>  
                   </div>
              </div>
            </div>
        
        
      </section>

    </div>
    
  )
}

export default Findhelper
